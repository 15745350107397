<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_connectors-intranet'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title" v-if="intranet">
          {{ intranet.name }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm @submit="submitForm" v-slot="{ errors }" class="form edit-form">
      <ul class="clebex-item-section pill" v-if="intranet">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input id="isActive" type="checkbox" v-model="isActive" />
                  <label for="isActive"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("connectors.intranet.is-active") }}
              </dd>
            </dl>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="intranetName"
                >{{ displayLabelName("connectors.intranet.name") }}
              </label>
              <Field
                name="name"
                as="input"
                rules="required"
                type="text"
                id="intranetName"
                v-model="intranet.name"
              />
              <span class="error-message" v-if="errors.name">
                {{ errors.name }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="intranetUrl"
                >{{ displayLabelName("connectors.intranet.url") }}
              </label>
              <Field
                name="url"
                as="input"
                rules="required"
                type="text"
                id="intranetUrl"
                v-model="intranet.url"
              />
              <span class="error-message" v-if="errors.number">
                {{ errors.number }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label
                class="clebex-section-input-label"
                for="intranetDescription"
                >{{ displayLabelName("connectors.intranet.description") }}
              </label>
              <Field
                name="description"
                as="input"
                type="text"
                id="intranetDescription"
                v-model="intranet.description"
              />
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "IntranetEdit",
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    ...mapState("intranet", ["intranet"])
  },
  created() {
    this.$store.commit("intranet/setIntranet", null, { root: true });
    this.getIntranet(this.$route.params.intranetId);
  },
  methods: {
    ...mapActions("intranet", ["getIntranet", "updateIntranet"]),
    submitForm(values) {
      values.is_active = Number(this.isActive);
      if (values) {
        this.updateIntranet({
          data: values,
          id: this.$route.params.intranetId
        }).then(() => {
          this.$router.push({
            name: "r_connectors-intranet"
          });
        });
      }
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  watch: {
    intranet() {
      if (this.intranet) {
        this.isActive = Boolean(this.intranet.is_active);
      }
    },
    "$route.params.intranetId": {
      handler(newId) {
        if (newId) {
          this.getIntranet(newId);
        }
      }
    }
  }
};
</script>
<style>
.top-header-menu .svg-icon {
  fill: #fff !important;
}
</style>
